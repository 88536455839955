<template>
    <b-badge :variant="buildAtivo.type">{{ buildAtivo.text }}</b-badge>
  </template>
  
  <script>
  export default {
    name: "ClienteAtivo",
    props: {
      bo_ativo: {
        type: String,
        default: ""
      }
    },
    computed: {
      buildAtivo() {
        let bo_ativo = {
          text: "",
          type: ""
        };
        switch (this.$props.bo_ativo) {
          case "S":
            bo_ativo.text = "Sim";
            bo_ativo.type = "success";
            break;
  
          case "N":
            bo_ativo.text = "Não";
            bo_ativo.type = "danger";
            break;
  
          default:
            bo_ativo.text = "Indefinido(" + this.$props.bo_ativo + ")";
            bo_ativo.type = "none";
            break;
        }
        return bo_ativo;
      }
    }
  };
</script>