<template>
  <span v-if="isLoading || hasAlert">
    <b-spinner
      v-if="isLoading"
      variant="secondary"
      label="Verificando alterações de produtos/impostos..."
      small
    ></b-spinner>
    <template v-else>
      <i
        :id="'alert' + orcamentoId"
        class="fa fa-exclamation-triangle text-danger d-inline"
      ></i>
      <b-popover
        :target="'alert' + orcamentoId"
        triggers="hover"
        placement="auto"
      >
        <template v-slot:title>Atenção</template>
        <!-- <p>
          Confira os seguintes alertas referente ao orçamento: {{ orcamentoId }}
        </p> -->
        <p v-if="data == null">
          Não foi possível fazer a consulta de mudanças de produtos, tente
          novamente mais tarde.
        </p>
       <!--  <div v-else class="table-container">
          <b-table-simple
            class="w-100 table-responsive"
            :hover="true"
            :striped="true"
            :small="true"
            :responsive="true"
            :bordered="true"
            stacked
          >
            <b-thead>
              <b-tr>
                <b-th class="text-center">Cód. Produto</b-th>
                <b-th class="text-center">Descrição</b-th>
                <b-th class="text-center">Campo</b-th>
                <b-th class="text-center">Antes</b-th>
                <b-th class="text-center">Depois</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template v-for="(itemList, produto) in data">
                <b-tr v-for="(item, index) in itemList" :key="index">
                  <b-td class="text-right" :stacked-heading="'Cód. Produto'">
                    {{ produto }}
                  </b-td>
                  <b-td :stacked-heading="'Descrição'">
                    {{ item.produto_descricao }}
                  </b-td>
                  <b-td :stacked-heading="'Campo.'">
                    {{ item.campo }}
                  </b-td>
                  <b-td :stacked-heading="'Vl. Anterior'" class="text-right">
                    {{ item.antes | moneyIfNumberElseString }}
                  </b-td>
                  <b-td :stacked-heading="'Vl. Atual'" class="text-right">
                    <div>
                      {{ item.agora | moneyIfNumberElseString }}
                    </div>
                  </b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
        </div> -->
        <p>
          Abra o orçamento para edição e clique em Atualizar Produtos para
          resolver os problemas.
        </p>
      </b-popover>
    </template>
  </span>
</template>

<style scoped>
.table-container {
  height: 15rem;
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>

<script>
import Orcamento from "../../../services/orcamento";

export default {
  name: "OrcamentoAlerta",
  props: {
    id: {
      type: String,
      default: "OrcamentoAlerta"
    },
    name: {
      type: String,
      default: "OrcamentoAlerta"
    },
    orcamentoId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    hasAlert() {
      if (this.data == null) return false;
      if (this.data.length <= 0) return false;
      return Object.keys(this.data).length > 0;
    }
  },
  data: () => ({
    isLoading: false,
    data: null
  }),
  mounted() {
    this.getProductChangeAlerts(this.orcamentoId);
  },
  methods: {
    getProductChangeAlerts(orcamento_id) {
      this.isLoading = true;
      this.data = null;
      Orcamento.getProductChangeAlerts(orcamento_id)
        .then(response => {
          this.data = response.data.data;
        })
        .catch(e => {
          if (
            !(
              e.response &&
              e.response.status &&
              e.response.status === 404 &&
              e.response.data
            )
          ) {
            this.$helper.showErrorResponse(e);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
