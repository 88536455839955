<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <span class="col-sm-12 col-md-2">
          <i class="icon-list"></i> Lista de Orçamentos
        </span>
        <b-button
          class="col-sm-12 col-md-2 float-right"
          size="sm"
          v-on:click="newBudget"
          v-if="!disabled"
          variant="primary"
        >
          <i class="fa fa-plus"></i> Novo Orcamento
        </b-button>
      </b-card-header>
      <b-card-body>
        <b-container>
          <i
            style="display: none"
            @click="reload"
            class="fa fa-times fa-lg icone-x"
            id="icone-x"
          ></i>

          <b-form-checkbox
            id="exibir-cancelados"
            name="exibir-cancelados"
            v-model="exibirCancelados"
            unchecked-value="false"
            @change="filtarExibirCancelados()"
          >
            <span>Exibir orçamentos cancelados</span>
          </b-form-checkbox>

          <my-data-table
            id="budgetsTable"
            :search="this.searchResult.search"
            :fields="this.searchResult.fields"
            :sortColumn="this.searchResult.sortColumn"
            :sortDirection="this.searchResult.sortDirection"
            :totalResults="this.searchResult.totalResults"
            :results="this.searchResult.results"
            :resultsPerPage="this.searchResult.resultsPerPage"
            :totalPages="this.searchResult.totalPages"
            :page="this.searchResult.page"
            :pages="this.searchResult.pages"
            :buttons="'end'"
            class="font-12"
            v-on:doSearch="loadBudgets"
            :showResultsPerPage="false"
          >
            <template v-slot:default="value">{{
              value.propertyValue | format(value.propertyName)
            }}</template>

            <template v-slot:status="value">
              <div class="text-center">
                <orcamento-status :status="value.propertyValue" />
              </div>
            </template>

            <template v-slot:cd_filial="value">
              <div class="text-center">
                <span :class="badgeFilial(value.propertyValue)">{{
                  getFilial(value.propertyValue)
                }}</span>
              </div>
            </template>

            <template v-slot:total="value">
              <div class="w-100 text-right moeda-real">
                {{ value.propertyValue | money }}
                <orcamento-alerta
                  v-if="
                    (value.row.status == 'D' || value.row.status == 'S') &&
                    value.propertyValue > 0
                  "
                  :orcamentoId="value.row.id"
                ></orcamento-alerta>
              </div>
            </template>
            <template v-slot:bo_ativo="value">
              <div class="text-center">
                <cliente-ativo :bo_ativo="value.propertyValue" />
              </div>
            </template>
            <template #buttons="value">
              <div class="text-center btn-acoes">
                <b-button
                  class="my-data-table-row-button"
                  size="sm"
                  variant="success"
                  v-on:click="openBudget(value.row)"
                >
                  <i class="fa fa-search-plus"></i>
                </b-button>

                <b-button
                  v-if="canEdit(value.row) && !disabled"
                  class="my-data-table-row-button"
                  size="sm"
                  variant="primary"
                  v-on:click="editBudget(value.row)"
                >
                  <i class="fa fa-edit"></i>
                </b-button>

                <b-button
                  v-if="canDelete(value.row) && !disabled"
                  class="my-data-table-row-button"
                  size="sm"
                  variant="danger"
                  v-on:click="confirmDelete(value.row)"
                >
                  <i class="fa fa-trash"></i>
                </b-button>
              </div>
            </template>
            <template #buttonsCaption>Ações</template>
          </my-data-table>
        </b-container>
      </b-card-body>
    </b-card>
  </div>
</template>

<style scoped>
.btn-acoes {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.font-12 {
  font-size: 12px;
}

#razao_socialbudgetsTable {
  width: 22% !important;
}

#idbudgetsTable {
  width: 9% !important;
}

#data_emissaobudgetsTable {
  width: 11% !important;
}

#data_validadebudgetsTable {
  width: 11% !important;
}

#cliente_codigobudgetsTable {
  width: 11% !important;
}

#previsao_fechamentobudgetsTable {
  width: 11% !important;
}

#totalbudgetsTable {
  width: 8% !important;
}

#statusbudgetsTable {
  width: 6% !important;
}

#button {
  width: 11% !important;
}

.badge-itj {
  color: #fff;
  background-color: #ff6d13;
  width: 39px;
}

.badge-mga {
  color: #fff;
  background-color: #0075eb;
}

.icone-x {
  position: absolute;
  margin-top: 0.7em;
  margin-left: 56em;
  z-index: 10;
  cursor: pointer;
}

@media screen and (max-width: 1460px) {
  .icone-x {
    margin-left: 55em;
  }
}

@media screen and (max-width: 1420px) {
  .icone-x {
    margin-left: 54em;
  }
}

@media screen and (max-width: 1400px) {
  .icone-x {
    margin-left: 53em;
  }
}

@media screen and (max-width: 1380px) {
  .icone-x {
    margin-left: 52em;
  }
}

@media screen and (max-width: 1365px) {
  .icone-x {
    margin-left: 51em;
  }
}
</style>

<script>
import Revenda from "../../../services/revenda";
import { httpNoLoadingFunctions } from "../../../services/configNoLoading";
import Orcamento from "../../../services/orcamento";
import MyDataTable from "@/components/ui/MyDataTable";
import OrcamentoStatus from "./OrcamentoStatus";
import ClienteAtivo from "../clientes/ClienteAtivo";
import OrcamentoAlerta from "./OrcamentoAlerta";
import GrupoPermissao from "../../../services/grupoPermissao";
import Vue from "vue";

export default {
  name: "Orcamentos",
  components: { MyDataTable, OrcamentoStatus, OrcamentoAlerta, ClienteAtivo },
  data() {
    return {
      firstLoadBudgets: true,
      searchResult: new Object(),
      disabled: false,
      exibirCancelados: false,
      params: {
        params: {
          resultsPerPage: 20,
          sortColumn: "id",
          sortDirection: "desc",
        },
      },
    };
  },
  computed: {},
  beforeMount() {
    this.validaPermissao();
    this.loadBudgets(this.params);
  },
  methods: {
    validaPermissao() {
      GrupoPermissao.getPermissao("/vendas/orcamentos").then((response) => {
        if (!response.data.data) this.$router.push("/");
        else this.disabled = response.data.data.edita != "1";
      });
    },
    canEdit(data) {
      return data.status == "D" || data.status == "S";
    },
    canDelete(data) {
      return data.status != "E" && data.status != "I";
    },
    loadBudgets(data = null) {
      let icone = document.getElementById("icone-x");
      let pesquisa = document.getElementById("pesquisa-input");

      if (pesquisa && pesquisa.value != "") {
        icone.style.display = "block";
      } else if (pesquisa) {
        icone.style.display = "none";
      }

      this.productChangeAlerts = [];
      if (!this.firstLoadBudgets)
        httpNoLoadingFunctions.cancelPendingRequests();

      let params = "exibirCancelados=" + this.exibirCancelados;

      Revenda.getOrcamentos(data, params)
        .then((response) => {
          this.searchResult = response.data.data;
          this.firstLoadBudgets = false;
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.status &&
            e.response.status === 404 &&
            e.response.data
          ) {
            this.searchResult = e.response.data.data;
          } else {
            this.$helper.showErrorResponse(e);
          }
        });
    },
    openBudget(row) {
      this.$router.push("orcamentos/orcamento/" + row.id);
    },
    editBudget(row) {
      this.$router.push("orcamentos/orcamento/" + row.id + "/editar");
    },
    newBudget() {
      this.$router.push("produtos");
    },
    getFilial(cd_filial) {
      return cd_filial == 1 ? "Maringá" : "Itajaí";
    },
    badgeFilial(cd_filial) {
      return cd_filial == 1 ? "badge badge-mga" : "badge badge-itj";
    },
    confirmDelete(row) {
      this.$bvModal
        .msgBoxConfirm(
          "Tem certeza que deseja excluir o Orçamento Nº." + row.id + "?",
          {
            title: "Atenção",
            size: "sm",
            buttonSize: "sm",
            okVariant: "success",
            okTitle: "Sim",
            cancelTitle: "Não",
            cancelVariant: "danger",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            Orcamento.deleteOrcamento(row.id)
              .then((response) => {
                this.$helper.showMsg(response.data.msg, response.data.type);
                this.loadBudgets();
              })
              .catch((e) => {
                this.$helper.showErrorResponse(e);
              });
          }
        })
        .catch((err) => {
          this.$helper.showErrorResponse(err);
        });
    },
    reload() {
      location.reload();
    },
    filtarExibirCancelados() {
      if (!this.exibirCancelados)
        this.params.params.page = this.searchResult.page;
      else this.params.params.page = 1;
      this.loadBudgets(this.params);
    },
  },
  filters: {
    format(value, field) {
      switch (field) {
        case "razao_social":
          return Vue.filter("toUpper")(value);
        case "data_emissao":
          return Vue.filter("formatDate")(value);
        case "data_validade":
          return Vue.filter("formatDate")(value);
        case "previsao_fechamento":
          return value == "0000-00-00" || value == null
            ? ""
            : Vue.filter("formatDate")(value);
        default:
          return value;
      }
    },
  },
};
</script>
